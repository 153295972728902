import gsap from "gsap";
import $ from "jquery"; 
import { Flip } from "gsap/Flip";

gsap.registerPlugin(Flip);

var src = 'https://mire.studio/cdr/home-visuels/';
var folders = ['01'];

// create 5 div .portion into home-hero-bg
$('.home-hero-bg').empty();
portionSizes = ['7.3%','13.5%','22.6%','32.9%','55.6%'];

for (var i = 0; i < 5; i++) {
    $('.home-hero-bg').append('<div style="width:'+ portionSizes[i] +'; overflow:hidden;" class=""><div style="height:100vh" class="portion"></div></div>');
}

var selectedFolder = gsap.utils.random(folders)
$('.portion').each( function(i){
    var index = i+1;
    $(this).css('background-image','url('+src+selectedFolder+'/'+index+'.jpg)');
    $(this).css('background-size','cover');
    $(this).css('background-position','center');
})



if ($('.home-hero-bg').length > 0) {  

    var homeBgWidth = $(window).width() < 768 ? '342%' : '100%';

    var animationComplete = false;
    const tl = gsap.timeline({onComplete: function(){
        animationComplete = true;
    }});


    tl.set('.home-hero-bg',{
        display:'flex',
        position:'absolute',
        top:0,
        height:'100%',
        width:homeBgWidth,
        zIndex:999,

    })

    tl.set('.main-header',{   
        visibility:'hidden',
    })

    tl.set('#home-actu',{   
        visibility:'hidden',
    })


    tl.set('#home-hero .container',{   
        opacity:0,
    })




    tl.from($(".portion"),{
        duration: 1,
        xPercent: -300,
        stagger:0.1,
        ease: "power2.inOut",

        onComplete: function(){
            const state = Flip.getState(".home-hero-bg");
            $('.home-hero-bg').prependTo('#home-hero');  
            gsap.set('.home-hero-bg', {
                zIndex: 0,
            })
            
            Flip.from(state, {
                duration: 1,
                zIndex: 999,
                delay:0.5,
                absolute: true,
                ease: "power2.inOut",
            });
            
        }
    })





    tl.to($(".portion"),{
        duration: 1.5,
        scale:2,
        stagger:0.1,
        ease: "power2.inOut",
        
    },'<')

    tl.set('.main-header',{   
        visibility:'visible',
    })

    tl.set('#home-actu',{   
        visibility:'visible',
    },'<')

    tl.to($(".portion"),{
        scale:1,
        height:'100%',

        duration: 1,
        stagger:0.05,
        ease: "power4.inOut",

    },'<')

    tl.to('#home-hero .container',{   
        opacity:1,
        onComplete: function(){
            AOS.refresh() 
        }
    })


    // $('.portion').on('mouseover', function(){
    //     gsap.to($(this).parent(),{
    //         duration: 2,
    //         width: '100vw',
    //         ease: "power2.inOut"
    //     })
    // });

    // remove proprty on mouseleave


    // $('.portion').on('mouseleave', function(){
    //     gsap.to($(this).parent(),{

    //         width: '100%',
    //         ease: "power2.inOut"
    //     })
    // });

    // $(window).mousemove( function(event){
    //     if (animationComplete){
    //         var x = event.pageX;
    //         var y = event.pageY;
    //         var width = $(window).width();
    //         var height = $(window).height();
    //         var xPercent = (x / width) * 100;
    //         var yPercent = (y / height) * 100;
    //         gsap.to($(".portion"),{
    //             duration: 0.5,
    //             backgroundPositionX: -event.pageX/15+'px',
    //             backgroundPositionY: -event.pageY/10+'px',
    //             stagger:0.1,
    //         })
    //     }

    // })

}